import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout/es"
import SEO from "../components/seo"
import HeroImage from "../components/ui-sections/hero-image"
import ImageGallery from "../components/ui-sections/image-gallery"
import TextSection from "../components/ui-sections/text-section"

const CuinaPage = (props) => {
	const heroText = "Cocina"
	const textSectionText = "Producto de temporada y de proximidad. Combinamos la cocina tradicional de los abuelos, con los matices de vanguardia gastronómica que tenemos hoy en día. Amamos nuestra tierra y sentimos como propios los guisantes de Llavaneres, la ternera de Girona, las gambas de Vilanova o las alcachofas del Prat. El Gallo del Penedès, el melocotón de Sant Pau d'Ordal y el tomate de Montserrat. Nos sabemos privilegiados de tener al alcance estos tesoros y nuestra cocina es la expresión más sincera de nuestra pasión. Esperamos que os guste."
	return (
	  <Layout location={props.location}>
	    <SEO 
	    	title="Cocina"
	    	lang="es"
	    	description={textSectionText}
	    />
	    <HeroImage 
	    	titleText={heroText}
	    	image="cuina" 
	    />
	    <TextSection textSectionText={textSectionText} />
	    <StaticQuery
	      query={graphql`
	        # Replace this comment with the GraphQL query above
	        {
	          allCuinaImagesEsYaml {
	            edges {
	              node {
	                title
	                image {
	                	id
	                  childImageSharp {
	                    fluid {
	                      aspectRatio
	                      originalImg
	                      # Use gatsby-image later for better user experience
	                      ...GatsbyImageSharpFluid
	        }}}}}}}
	      `}
	      render={data => (
	        <ImageGallery
	          itemsPerRow={[1, 3]} // This will be changed to `[2, 3]` later
	          images={data.allCuinaImagesEsYaml.edges.map(({ node }) => ({
	          	id: node.image.id,
	            ...node.image.childImageSharp.fluid,
	            caption: `${node.title}`,
	          }))}
	        />
	      )}
	    />
	  </Layout>
	)
}

export default CuinaPage
